import useAsyncFetch from "./useAsyncFetch";
import useGamesState from "./useGamesState";
import useIsGuest from "./useIsGuest";

const useHomeData = ({
	immediate,
	cached,
	server
}: { immediate?: boolean; cached?: boolean; server?: boolean } = {}) => {
	const isGuest = useIsGuest();
	const { add: addGames, select } = useGamesState();
	const asyncData = useAsyncFetch({
		path: "/rest/page/home/",
		method: "get",
		options: {
			key: "homePage",
			transform: (data) => {
				const gameOfWeek = data.payload?.games?.issues?.["popular-games"]?.gameOfWeek;

				return {
					seo: data.seo,
					jackpots: data.payload.jackpots,
					banners: data.payload.banners,
					questData: data.payload.questData,
					infoPlate: data.payload.infoPlate,
					promotions: data.payload.promotions,
					gameOfWeek: addGames(gameOfWeek ? [gameOfWeek] : []),
					promoBanners: data.payload.promoBanners || [],
					games: {
						jackpot: addGames(data.payload?.games?.issues?.jackpot?.games || []),
						holdNLink: addGames(data.payload?.games?.issues?.["hold-n-link"]?.games || []),
						popularGames: addGames(data.payload?.games?.issues?.["popular-games"]?.games || []),
						hotGames: addGames(data.payload?.games?.issues?.["hot-games"]?.games || []),
						newGames: addGames(data.payload?.games?.issues?.["new-games"]?.games || []),
						fishing: addGames(data.payload?.games?.issues?.fishing?.games || []),
						featuredGames: addGames(data.payload?.games?.issues?.["featured-games"]?.games || []),
						bonusBuyGames: addGames(data.payload?.games?.issues?.["bonus-buy"]?.games || []),
						missedItGames: addGames(data.payload?.games?.issues?.["missed-it"]?.games || []),
						topGames: addGames(data.payload?.games?.issues?.["top-games"]?.games || []),
						fruitsGames: addGames(data.payload?.games?.issues?.["fruits-games"]?.games || []),
						wildGames: addGames(data.payload?.games?.issues?.["wild-life"]?.games || []),
						bonusBuy: addGames(data.payload?.games?.issues?.["bonus-buy"]?.games || []),
						bonusCombo: addGames(data.payload?.games?.issues?.["bonus-combo"]?.games || []),
						recommend: addGames(data.payload?.games?.issues?.recommend?.games || []),
						intoTheWild: addGames(data.payload?.games?.issues?.["into-the-wild"]?.games || [])
					}
				};
			},
			watch: [isGuest],
			server,
			cached: cached === false ? undefined : true,
			immediate
		}
	});

	const jackpotGames = computed(() => select(asyncData.data.value?.games?.jackpot || []));
	const holdNLinksGames = computed(() => select(asyncData.data.value?.games?.holdNLink || []));
	const gamesWithWeekGame = computed(() => select(asyncData.data.value?.games?.popularGames || []));
	const hotGames = computed(() => select(asyncData.data.value?.games?.hotGames || []));
	const newGames = computed(() => select(asyncData.data.value?.games?.newGames || []));
	const fishingGames = computed(() => select(asyncData.data.value?.games?.fishing || []));
	const featuredGames = computed(() => select(asyncData.data.value?.games?.featuredGames || []));
	const bonusBuyGames = computed(() => select(asyncData.data.value?.games?.bonusBuyGames || []));
	const missedItGames = computed(() => select(asyncData.data.value?.games?.missedItGames || []));
	const recommendGames = computed(() => select(asyncData.data.value?.games?.recommend || []));
	const intoTheWildGames = computed(() => select(asyncData.data.value?.games?.intoTheWild || []));

	return {
		...asyncData,
		jackpotGames,
		holdNLinksGames,
		fishingGames,
		gamesWithWeekGame,
		hotGames,
		newGames,
		featuredGames,
		bonusBuyGames,
		missedItGames,
		recommendGames,
		intoTheWildGames
	};
};

export default useHomeData;
