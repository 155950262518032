import { default as indexPCJzCfcvYVMeta } from "/app/apps/tao/pages/affiliates/index.vue?macro=true";
import { default as partners_45accountfO17MT8xfKMeta } from "/app/apps/tao/pages/affiliates/partners-account.vue?macro=true";
import { default as gamea1Ik4Tmz6oMeta } from "/app/apps/tao/pages/game.vue?macro=true";
import { default as how_45it_45worksd0ixYKS1j9Meta } from "/app/apps/tao/pages/how-it-works.vue?macro=true";
import { default as index2RLSTf7Kw1Meta } from "/app/apps/tao/pages/index.vue?macro=true";
import { default as _91pageName_93Oq2QmdLFVPMeta } from "/app/apps/tao/pages/issues/[pageName].vue?macro=true";
import { default as favoritesnSE3oj4quMMeta } from "/app/apps/tao/pages/issues/favorites.vue?macro=true";
import { default as _91name_93MgFaIUEKGiMeta } from "/app/apps/tao/pages/page/[name].vue?macro=true";
import { default as piggy_45bankNiiN7yp6y6Meta } from "/app/apps/tao/pages/piggy-bank.vue?macro=true";
import { default as promotionsMvPJupSpSRMeta } from "/app/apps/tao/pages/promotions.vue?macro=true";
import { default as questpwBQTm9aYnMeta } from "/app/apps/tao/pages/quest.vue?macro=true";
import { default as referralDLAp0ivBV5Meta } from "/app/apps/tao/pages/referral.vue?macro=true";
import { default as scratch_45cardsR8RQQprzeHMeta } from "/app/apps/tao/pages/scratch-cards.vue?macro=true";
import { default as tournamentsv1qrGgDlVuMeta } from "/app/apps/tao/pages/tournaments.vue?macro=true";
import { default as buttonsVuyRhKuNghMeta } from "/app/apps/tao/pages/ui/buttons.vue?macro=true";
import { default as colorsD3k37mM0SJMeta } from "/app/apps/tao/pages/ui/colors.vue?macro=true";
import { default as indexDGLSeui5T6Meta } from "/app/apps/tao/pages/ui/index.vue?macro=true";
import { default as typographytLwCIMXMeDMeta } from "/app/apps/tao/pages/ui/typography.vue?macro=true";
export default [
  {
    name: indexPCJzCfcvYVMeta?.name ?? "affiliates___en",
    path: indexPCJzCfcvYVMeta?.path ?? "/affiliates",
    meta: indexPCJzCfcvYVMeta || {},
    alias: indexPCJzCfcvYVMeta?.alias || [],
    redirect: indexPCJzCfcvYVMeta?.redirect,
    component: () => import("/app/apps/tao/pages/affiliates/index.vue").then(m => m.default || m)
  },
  {
    name: partners_45accountfO17MT8xfKMeta?.name ?? "affiliates-partners-account___en",
    path: partners_45accountfO17MT8xfKMeta?.path ?? "/affiliates/partners-account",
    meta: partners_45accountfO17MT8xfKMeta || {},
    alias: partners_45accountfO17MT8xfKMeta?.alias || [],
    redirect: partners_45accountfO17MT8xfKMeta?.redirect,
    component: () => import("/app/apps/tao/pages/affiliates/partners-account.vue").then(m => m.default || m)
  },
  {
    name: gamea1Ik4Tmz6oMeta?.name ?? "game___en",
    path: gamea1Ik4Tmz6oMeta?.path ?? "/game",
    meta: gamea1Ik4Tmz6oMeta || {},
    alias: gamea1Ik4Tmz6oMeta?.alias || [],
    redirect: gamea1Ik4Tmz6oMeta?.redirect,
    component: () => import("/app/apps/tao/pages/game.vue").then(m => m.default || m)
  },
  {
    name: how_45it_45worksd0ixYKS1j9Meta?.name ?? "how-it-works___en",
    path: how_45it_45worksd0ixYKS1j9Meta?.path ?? "/how-it-works",
    meta: how_45it_45worksd0ixYKS1j9Meta || {},
    alias: how_45it_45worksd0ixYKS1j9Meta?.alias || [],
    redirect: how_45it_45worksd0ixYKS1j9Meta?.redirect,
    component: () => import("/app/apps/tao/pages/how-it-works.vue").then(m => m.default || m)
  },
  {
    name: index2RLSTf7Kw1Meta?.name ?? "index___en",
    path: index2RLSTf7Kw1Meta?.path ?? "/",
    meta: index2RLSTf7Kw1Meta || {},
    alias: index2RLSTf7Kw1Meta?.alias || [],
    redirect: index2RLSTf7Kw1Meta?.redirect,
    component: () => import("/app/apps/tao/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91pageName_93Oq2QmdLFVPMeta?.name ?? "issues-pageName___en",
    path: _91pageName_93Oq2QmdLFVPMeta?.path ?? "/issues/:pageName()",
    meta: _91pageName_93Oq2QmdLFVPMeta || {},
    alias: _91pageName_93Oq2QmdLFVPMeta?.alias || [],
    redirect: _91pageName_93Oq2QmdLFVPMeta?.redirect,
    component: () => import("/app/apps/tao/pages/issues/[pageName].vue").then(m => m.default || m)
  },
  {
    name: favoritesnSE3oj4quMMeta?.name ?? "issues-favorites___en",
    path: favoritesnSE3oj4quMMeta?.path ?? "/issues/favorites",
    meta: favoritesnSE3oj4quMMeta || {},
    alias: favoritesnSE3oj4quMMeta?.alias || [],
    redirect: favoritesnSE3oj4quMMeta?.redirect,
    component: () => import("/app/apps/tao/pages/issues/favorites.vue").then(m => m.default || m)
  },
  {
    name: _91name_93MgFaIUEKGiMeta?.name ?? "page-name___en",
    path: _91name_93MgFaIUEKGiMeta?.path ?? "/page/:name()",
    meta: _91name_93MgFaIUEKGiMeta || {},
    alias: _91name_93MgFaIUEKGiMeta?.alias || [],
    redirect: _91name_93MgFaIUEKGiMeta?.redirect,
    component: () => import("/app/apps/tao/pages/page/[name].vue").then(m => m.default || m)
  },
  {
    name: piggy_45bankNiiN7yp6y6Meta?.name ?? "piggy-bank___en",
    path: piggy_45bankNiiN7yp6y6Meta?.path ?? "/piggy-bank",
    meta: piggy_45bankNiiN7yp6y6Meta || {},
    alias: piggy_45bankNiiN7yp6y6Meta?.alias || [],
    redirect: piggy_45bankNiiN7yp6y6Meta?.redirect,
    component: () => import("/app/apps/tao/pages/piggy-bank.vue").then(m => m.default || m)
  },
  {
    name: promotionsMvPJupSpSRMeta?.name ?? "promotions___en",
    path: promotionsMvPJupSpSRMeta?.path ?? "/promotions",
    meta: promotionsMvPJupSpSRMeta || {},
    alias: promotionsMvPJupSpSRMeta?.alias || [],
    redirect: promotionsMvPJupSpSRMeta?.redirect,
    component: () => import("/app/apps/tao/pages/promotions.vue").then(m => m.default || m)
  },
  {
    name: questpwBQTm9aYnMeta?.name ?? "quest___en",
    path: questpwBQTm9aYnMeta?.path ?? "/quest",
    meta: questpwBQTm9aYnMeta || {},
    alias: questpwBQTm9aYnMeta?.alias || [],
    redirect: questpwBQTm9aYnMeta?.redirect,
    component: () => import("/app/apps/tao/pages/quest.vue").then(m => m.default || m)
  },
  {
    name: referralDLAp0ivBV5Meta?.name ?? "referral___en",
    path: referralDLAp0ivBV5Meta?.path ?? "/referral",
    meta: referralDLAp0ivBV5Meta || {},
    alias: referralDLAp0ivBV5Meta?.alias || [],
    redirect: referralDLAp0ivBV5Meta?.redirect,
    component: () => import("/app/apps/tao/pages/referral.vue").then(m => m.default || m)
  },
  {
    name: scratch_45cardsR8RQQprzeHMeta?.name ?? "scratch-cards___en",
    path: scratch_45cardsR8RQQprzeHMeta?.path ?? "/scratch-cards",
    meta: scratch_45cardsR8RQQprzeHMeta || {},
    alias: scratch_45cardsR8RQQprzeHMeta?.alias || [],
    redirect: scratch_45cardsR8RQQprzeHMeta?.redirect,
    component: () => import("/app/apps/tao/pages/scratch-cards.vue").then(m => m.default || m)
  },
  {
    name: tournamentsv1qrGgDlVuMeta?.name ?? "tournaments___en",
    path: tournamentsv1qrGgDlVuMeta?.path ?? "/tournaments",
    meta: tournamentsv1qrGgDlVuMeta || {},
    alias: tournamentsv1qrGgDlVuMeta?.alias || [],
    redirect: tournamentsv1qrGgDlVuMeta?.redirect,
    component: () => import("/app/apps/tao/pages/tournaments.vue").then(m => m.default || m)
  },
  {
    name: buttonsVuyRhKuNghMeta?.name ?? "ui-buttons___en",
    path: buttonsVuyRhKuNghMeta?.path ?? "/ui/buttons",
    meta: buttonsVuyRhKuNghMeta || {},
    alias: buttonsVuyRhKuNghMeta?.alias || [],
    redirect: buttonsVuyRhKuNghMeta?.redirect,
    component: () => import("/app/apps/tao/pages/ui/buttons.vue").then(m => m.default || m)
  },
  {
    name: colorsD3k37mM0SJMeta?.name ?? "ui-colors___en",
    path: colorsD3k37mM0SJMeta?.path ?? "/ui/colors",
    meta: colorsD3k37mM0SJMeta || {},
    alias: colorsD3k37mM0SJMeta?.alias || [],
    redirect: colorsD3k37mM0SJMeta?.redirect,
    component: () => import("/app/apps/tao/pages/ui/colors.vue").then(m => m.default || m)
  },
  {
    name: indexDGLSeui5T6Meta?.name ?? "ui___en",
    path: indexDGLSeui5T6Meta?.path ?? "/ui",
    meta: indexDGLSeui5T6Meta || {},
    alias: indexDGLSeui5T6Meta?.alias || [],
    redirect: indexDGLSeui5T6Meta?.redirect,
    component: () => import("/app/apps/tao/pages/ui/index.vue").then(m => m.default || m)
  },
  {
    name: typographytLwCIMXMeDMeta?.name ?? "ui-typography___en",
    path: typographytLwCIMXMeDMeta?.path ?? "/ui/typography",
    meta: typographytLwCIMXMeDMeta || {},
    alias: typographytLwCIMXMeDMeta?.alias || [],
    redirect: typographytLwCIMXMeDMeta?.redirect,
    component: () => import("/app/apps/tao/pages/ui/typography.vue").then(m => m.default || m)
  }
]