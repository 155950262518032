/* eslint-disable indent */
// @ts-nocheck

import type { AsyncDataOptions } from "#app";

import {
	v1,
	type ExtractFromAPI,
	type PossibleMethods,
	type Parameters as Params,
	replacePathParameters
} from "@netgame/openapi";
import type { NitroFetchOptions, NitroFetchRequest } from "nitropack";
import { hash } from "ohash";

import useAsync from "./useAsync";
import type { KeysOf } from "./useAsync";

export const dedupePromises = new Map();

export type AsyncDataOptionsWithCache<
	Response,
	DataT = Response,
	PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
	DefaultT = null
> = AsyncDataOptions<Response, DataT, PickKeys, DefaultT> & {
	cached?: true;
	key?: string;
};

const useAsyncFetch = <
	Path extends keyof v1.paths,
	Method extends keyof v1.paths[Path],
	Response extends ExtractFromAPI<v1.paths, Path, Method>,
	DataE = Error,
	DataT = Response,
	PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
	DefaultT = null
>({
	path,
	method,
	options,
	fetchOptions
}: {
	path: Path;
	method: Method;
	options?: AsyncDataOptionsWithCache<Response, DataT, PickKeys, DefaultT>;
	fetchOptions?:
		| (NitroFetchOptions<NitroFetchRequest> & Params<v1.paths[Path][Method]>)
		| (() => NitroFetchOptions<NitroFetchRequest> & Params<v1.paths[Path][Method]>);
}) => {
	const { path: parametersPaths } = (fetchOptions || {}) as unknown as { path: Record<string, string> };
	const headers = useRequestHeaders();
	const isQuasarClient = isClient && typeof window.__Q_META__ === "object";
	const baseURL = isQuasarClient ? undefined : "/api";
	const endpoint = replacePathParameters(path, parametersPaths);
	const asyncData = useAsync<Response, DataE, DataT, PickKeys, DefaultT>(
		options?.key || endpoint,
		() => {
			const requestParams = {
				baseURL,
				headers,
				...(typeof fetchOptions === "function" ? fetchOptions() : fetchOptions),
				method: method as PossibleMethods | undefined
			};

			const hashStr = hash({
				endpoint: options?.key || endpoint,
				requestParams
			});

			if (dedupePromises.has(hashStr)) {
				return dedupePromises.get(hashStr);
			}
			const promise = $fetch<Response>(endpoint, requestParams);

			dedupePromises.set(hashStr, promise);

			promise.finally(() => {
				dedupePromises.delete(hashStr);
			});

			return promise;
		},
		options
	);

	return asyncData;
};

export default useAsyncFetch;
