import validate from "/app/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@22.4.1_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_lbhubh3tvj75hwaifhxf23zxzq/node_modules/nuxt/dist/pages/runtime/validate.js";
import request_45handle_45global from "/app/apps/tao/middleware/request-handle.global.ts";
import manifest_45route_45rule from "/app/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@22.4.1_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_lbhubh3tvj75hwaifhxf23zxzq/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  request_45handle_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "route-guard": () => import("/app/apps/tao/middleware/route-guard.ts")
}