import revive_payload_client_qxELGemnsE from "/app/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@22.4.1_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_lbhubh3tvj75hwaifhxf23zxzq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_LnNNN69CJF from "/app/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@22.4.1_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_lbhubh3tvj75hwaifhxf23zxzq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Pp9yRLASZW from "/app/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@22.4.1_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_lbhubh3tvj75hwaifhxf23zxzq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_YBErfNVABg from "/app/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@22.4.1_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_lbhubh3tvj75hwaifhxf23zxzq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_HspzwkSMuY from "/app/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@22.4.1_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_lbhubh3tvj75hwaifhxf23zxzq/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/app/apps/tao/.nuxt/components.plugin.mjs";
import prefetch_client_pBpWOyZRxe from "/app/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@22.4.1_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_lbhubh3tvj75hwaifhxf23zxzq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_dyxaxO9Ogt from "/app/node_modules/.pnpm/@dargmuesli+nuxt-cookie-control@8.3.2_magicast@0.3.4_rollup@4.21.0_webpack@5.93.0/node_modules/@dargmuesli/nuxt-cookie-control/dist/runtime/plugin.mjs";
import firebase_client_r9dUwZeGdA from "/app/node_modules/.pnpm/@netgame+push-notifications@1.9.8_magicast@0.3.4_rollup@4.21.0/node_modules/@netgame/push-notifications/dist/runtime/plugins/firebase.client.mjs";
import i18n_30agGZ2jLG from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.1.1_magicast@0.3.4_rollup@4.21.0_vue@3.4.38_typescript@5.4.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_Iaw5Gx7sse from "/app/node_modules/.pnpm/@nuxtjs+device@3.1.0_magicast@0.3.4_rollup@4.21.0/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import chunk_reload_client_bNGR6PsRcN from "/app/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@22.4.1_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_lbhubh3tvj75hwaifhxf23zxzq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _01_raygun_client_l2Z0oTPCB4 from "/app/node_modules/.pnpm/@netgame+layer-raygun@1.6.7_magicast@0.3.4_rollup@4.21.0/node_modules/@netgame/layer-raygun/plugins/01.raygun.client.ts";
import beforeinstallprompt_client_GhFSYpFCrB from "/app/apps/tao/plugins/beforeinstallprompt.client.ts";
import mask_Tk3SUcMqzt from "/app/apps/tao/plugins/mask.ts";
import toastify_ScRgZgP9op from "/app/apps/tao/plugins/toastify.ts";
export default [
  revive_payload_client_qxELGemnsE,
  unhead_LnNNN69CJF,
  router_Pp9yRLASZW,
  payload_client_YBErfNVABg,
  check_outdated_build_client_HspzwkSMuY,
  components_plugin_KR1HBZs4kY,
  prefetch_client_pBpWOyZRxe,
  plugin_dyxaxO9Ogt,
  firebase_client_r9dUwZeGdA,
  i18n_30agGZ2jLG,
  plugin_Iaw5Gx7sse,
  chunk_reload_client_bNGR6PsRcN,
  _01_raygun_client_l2Z0oTPCB4,
  beforeinstallprompt_client_GhFSYpFCrB,
  mask_Tk3SUcMqzt,
  toastify_ScRgZgP9op
]