<script setup lang="ts">
const { modals, close, open } = useTaoModals();
const { data: appInit } = await useAppInitData();
const route = useRoute();
const { isOneClickRegister } = useOtpGetFlow();
const { isSeasonQuest } = useQuestData({ immediate: false });
useCookiesSetPromoCode();

const dictionary = new Map([
	["login", "LazyOModalLogin"],
	["register", isOneClickRegister ? "LazyOModalOneClickSignup" : "LazyOModalSignup"],
	["register1", "LazyOModalSignup"]
]);

onMounted(() => {
	const { openModal, errorMessage, invited_by: invitedBy } = route.query;

	if (openModal === "app-flip-the-coin-modal") {
		open("LazyOModalFlipTheCoin");
	}

	if (openModal === "app-challenge-season-modal" && isSeasonQuest.value) {
		open("LazyOModalChallengeSeason");
	}

	if (openModal === "app-season-modal" && appInit.value?.season?.isEnabled) {
		open("LazyOModalSeasonTower");
	}

	if (appInit.value?.isGuest === false) {
		return;
	}

	if (invitedBy) {
		setTimeout(() => {
			open("LazyOModalSignup");
		}, 1200);
	}

	if (typeof openModal !== "string" || !dictionary.has(openModal)) {
		return;
	}
	const modal = dictionary.get(openModal);
	if (!modal) {
		return;
	}
	const error = Array.isArray(errorMessage) ? errorMessage[0] : errorMessage;
	const errorString = typeof error === "string" ? error : undefined;

	open(modal as Parameters<typeof open>[0], { error: errorString });
});
</script>

<template>
	<div>
		<Teleport to="body">
			<TransitionGroup :name="modals.prevSize === 0 || modals.nextSize === 0 ? 'modal' : 'multiple'" appear>
				<AOverlay
					v-for="[ModalComponent, properties] in modals.items.entries()"
					:id="ModalComponent.replace('LazyO', '')"
					:key="ModalComponent"
					:modifiers="['flex-center', 'fixed', 'auto']"
				>
					<component v-bind="{ onClose: () => close(ModalComponent), ...properties }" :is="ModalComponent" />
				</AOverlay>
			</TransitionGroup>
		</Teleport>
	</div>
</template>

<style scoped lang="scss">
.modal-enter-active,
.modal-leave-active {
	transition: opacity 350ms ease-in-out;
}

.modal-enter-from,
.modal-leave-to {
	opacity: 0;
}

.overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 9998;
	cursor: auto;

	&#ModalJackpotsGames,
	&#ModalMoneyBox {
		z-index: 10000;
		overflow: hidden;
		align-items: initial;
	}
	&#ModalTournamentHowItWorks {
		align-items: initial;
	}
	&#ModalOneClickSignup,
	&#ModalOneClickSignupComplete,
	&#ModalDepositStreakHowItWorks,
	&#ModalRacesGames {
		z-index: 100002;
	}
}
</style>
