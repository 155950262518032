export type EventGA =
	| "click_button"
	| "open_popup"
	| "close_popup"
	| "tooltips"
	| "verification"
	| "forgot_password"
	| "registration"
	| "change_form"
	| "logout"
	| "purchase"
	| "gameplay"
	| "login"
	| "promocode"
	| "deposit"
	| "purchase_d2"
	| "change_form"
	| "error"
	| "registration_success"
	| "install_app_popup_show"
	| "push_opened"
	| "captcha_fail";

const dispatchGAEvent = (payload: { event: EventGA } & Record<string, string | number>) => {
	window.dataLayer?.push?.(payload);
};

export default dispatchGAEvent;
