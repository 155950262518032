import LazyOModalAccountChangePassword from "~/organizms/O/Modal/Account/ChangePassword.vue";
import LazyOModalAccountConfirmSubscription from "~/organizms/O/Modal/Account/ConfirmSubscription.vue";
import LazyOModalAccountEditContact from "~/organizms/O/Modal/Account/EditContact.vue";
import LazyOModalAffiliateTransfer from "~/organizms/O/Modal/AffiliateTransfer.vue";
import LazyOModalChallengeSeason from "~/organizms/O/Modal/ChallengeSeason.vue";
import LazyOModalChallengeSeasonWinner from "~/organizms/O/Modal/ChallengeSeasonWinner.vue";
import LazyOModalDepositStreakHowItWorks from "~/organizms/O/Modal/DepositStreak/HowItWorks.vue";
import LazyOModalDepositStreak from "~/organizms/O/Modal/DepositStreak/index.vue";
import LazyOModalDepositStreakWelcome from "~/organizms/O/Modal/DepositStreak/Welcome.vue";
import LazyOModalEmailConfirm from "~/organizms/O/Modal/EmailConfirm.vue";
import LazyOModalFlipTheCoinRules from "~/organizms/O/Modal/FlipTheCoin/FlipTheCoinRules.vue";
import LazyOModalFlipTheCoinUsed from "~/organizms/O/Modal/FlipTheCoin/FlipTheCoinUsed.vue";
import LazyOModalFlipTheCoin from "~/organizms/O/Modal/FlipTheCoin/index.vue";
import LazyOModalForgotPassword from "~/organizms/O/Modal/ForgotPassword.vue";
import LazyOModalForgotPasswordSent from "~/organizms/O/Modal/ForgotPasswordSent.vue";
import LazyOModalGame from "~/organizms/O/Modal/Game.vue";
import LazyOModalGamePreview from "~/organizms/O/Modal/GamePreview.vue";
import LazyOModalInsufficientFunds from "~/organizms/O/Modal/InsufficientFunds.vue";
import LazyOModalInsufficientWithdrawal from "~/organizms/O/Modal/InsufficientWithdrawal.vue";
import LazyOModalInviteFriends from "~/organizms/O/Modal/InviteFriends.vue";
import LazyOModalJackpotsGames from "~/organizms/O/Modal/JackpotsGames.vue";
import LazyOModalLogin from "~/organizms/O/Modal/Login.vue";
import LazyOModalMagicBoxes from "~/organizms/O/Modal/MagicBoxes.vue";
import LazyOModalMoneyBox from "~/organizms/O/Modal/MoneyBox.vue";
import LazyOModalOneClickSignup from "~/organizms/O/Modal/OneClickSignup.vue";
import LazyOModalOneClickSignupComplete from "~/organizms/O/Modal/OneClickSignupComplete.vue";
import LazyOModalPasswordRemind from "~/organizms/O/Modal/PasswordRemind.vue";
import LazyOModalPhoneConfirmation from "~/organizms/O/Modal/PhoneConfirmation.vue";
import LazyOModalPhoneConfirmationInfo from "~/organizms/O/Modal/PhoneConfirmationInfo.vue";
import LazyOModalPiggyBankPrizeWin from "~/organizms/O/Modal/PiggyBank/PrizeWin.vue";
import LazyOModalPresetPackage from "~/organizms/O/Modal/PresetPackage.vue";
import LazyOModalPromoHowItWorks from "~/organizms/O/Modal/PromoHowItWorks.vue";
import LazyOModalPromoInviteFriends from "~/organizms/O/Modal/PromoInviteFriends.vue";
import LazyOModalPwaApps from "~/organizms/O/Modal/Pwa/Apps.vue";
import LazyOModalPwaNotification from "~/organizms/O/Modal/Pwa/Notification.vue";
import LazyOModalQuestAvailableTask from "~/organizms/O/Modal/QuestAvailableTask.vue";
import LazyOModalQuestGetStarted from "~/organizms/O/Modal/QuestGetStarted.vue";
import LazyOModalQuestHowItWorks from "~/organizms/O/Modal/QuestHowItWorks.vue";
import LazyOModalQuestLeaderBoard from "~/organizms/O/Modal/QuestLeaderBoard.vue";
import LazyOModalQuestTaskCompleted from "~/organizms/O/Modal/QuestTaskCompleted.vue";
import LazyOModalQuestWinnerAnimation from "~/organizms/O/Modal/QuestWinnerAnimation.vue";
import LazyOModalQuestWinnerNotification from "~/organizms/O/Modal/QuestWinnerNotification.vue";
import LazyOModalRacesGames from "~/organizms/O/Modal/RacesGames.vue";
import LazyOModalReferralInfo from "~/organizms/O/Modal/ReferralInfo.vue";
import LazyOModalRegistrationComplete from "~/organizms/O/Modal/RegistrationComplete.vue";
import LazyOModalReRegistration from "~/organizms/O/Modal/ReRegistration.vue";
import LazyOModalRulesChallengeSeason from "~/organizms/O/Modal/RulesChallengeSeason.vue";
import LazyOModalRulesMagicBoxes from "~/organizms/O/Modal/RulesMagicBoxes.vue";
import LazyOModalScratchCardsFeature from "~/organizms/O/Modal/ScratchCards/Feature.vue";
import LazyOModalScratchCardsPlayForWin from "~/organizms/O/Modal/ScratchCards/PlayForWin.vue";
import LazyOModalScratchCardsWelcome from "~/organizms/O/Modal/ScratchCards/Welcome.vue";
import LazyOModalScratchCardsYouWon from "~/organizms/O/Modal/ScratchCards/YouWon.vue";
import LazyOModalSeasonActivated from "~/organizms/O/Modal/Season/Activated.vue";
import LazyOModalSeasonFishingGames from "~/organizms/O/Modal/Season/FishingGames.vue";
import LazyOModalSeasonGames from "~/organizms/O/Modal/Season/Games.vue";
import LazyOModalSeasonHowItWorks from "~/organizms/O/Modal/Season/HowItWorks.vue";
import LazyOModalSeasonTower from "~/organizms/O/Modal/Season/Tower.vue";
import LazyOModalSeasonWelcome from "~/organizms/O/Modal/Season/Welcome.vue";
import LazyOModalSignup from "~/organizms/O/Modal/Signup.vue";
import LazyOModalSignupExitPopup from "~/organizms/O/Modal/SignupExitPopup.vue";
import LazyOModalSuccessReferral from "~/organizms/O/Modal/SuccessReferral.vue";
import LazyOModalSwitchToSweeps from "~/organizms/O/Modal/SwitchToSweeps.vue";
import LazyOModalTaoExitPopup from "~/organizms/O/Modal/TaoExitPopup.vue";
import LazyOModalTermsDialog from "~/organizms/O/Modal/TermsDialog.vue";
import LazyOModalTournamentGames from "~/organizms/O/Modal/Tournament/Games.vue";
import LazyOModalTournamentHowItWorks from "~/organizms/O/Modal/Tournament/HowItWorks.vue";
import LazyOModalTournamentIncreaseBet from "~/organizms/O/Modal/Tournament/IncreaseBet.vue";
import LazyOModalTournamentLeaderboard from "~/organizms/O/Modal/Tournament/Leaderboard.vue";
import LazyOModalTournamentPrizes from "~/organizms/O/Modal/Tournament/Prizes.vue";
import LazyOModalWelcome from "~/organizms/O/Modal/Welcome.vue";

type Modals = {
	LazyOModalForgotPassword: InstanceType<typeof LazyOModalForgotPassword>["$props"];
	LazyOModalForgotPasswordSent: InstanceType<typeof LazyOModalForgotPasswordSent>["$props"];
	LazyOModalPasswordRemind: InstanceType<typeof LazyOModalPasswordRemind>["$props"];
	LazyOModalLogin: InstanceType<typeof LazyOModalLogin>["$props"];
	LazyOModalSignup: InstanceType<typeof LazyOModalSignup>["$props"];
	LazyOModalOneClickSignup: InstanceType<typeof LazyOModalOneClickSignup>["$props"];
	LazyOModalOneClickSignupComplete: InstanceType<typeof LazyOModalOneClickSignupComplete>["$props"];
	LazyOModalSignupExitPopup: InstanceType<typeof LazyOModalSignupExitPopup>["$props"];
	LazyOModalEmailConfirm: InstanceType<typeof LazyOModalEmailConfirm>["$props"];
	LazyOModalMagicBoxes: InstanceType<typeof LazyOModalMagicBoxes>["$props"];
	LazyOModalRulesMagicBoxes: InstanceType<typeof LazyOModalRulesMagicBoxes>["$props"];
	LazyOModalAccountEditContact: InstanceType<typeof LazyOModalAccountEditContact>["$props"];
	LazyOModalAccountChangePassword: InstanceType<typeof LazyOModalAccountChangePassword>["$props"];
	LazyOModalAccountConfirmSubscription: InstanceType<typeof LazyOModalAccountConfirmSubscription>["$props"];
	LazyOModalPhoneConfirmation: InstanceType<typeof LazyOModalPhoneConfirmation>["$props"];
	LazyOModalPhoneConfirmationInfo: InstanceType<typeof LazyOModalPhoneConfirmationInfo>["$props"];
	LazyOModalRacesGames: InstanceType<typeof LazyOModalRacesGames>["$props"];
	LazyOModalRegistrationComplete: InstanceType<typeof LazyOModalRegistrationComplete>["$props"];
	LazyOModalReRegistration: InstanceType<typeof LazyOModalReRegistration>["$props"];
	LazyOModalTermsDialog: InstanceType<typeof LazyOModalTermsDialog>["$props"];
	LazyOModalGame: InstanceType<typeof LazyOModalGame>["$props"];
	LazyOModalGamePreview: InstanceType<typeof LazyOModalGamePreview>["$props"];
	LazyOModalQuestGetStarted: InstanceType<typeof LazyOModalQuestGetStarted>["$props"];
	LazyOModalQuestTaskCompleted: InstanceType<typeof LazyOModalQuestTaskCompleted>["$props"];
	LazyOModalPwaApps: InstanceType<typeof LazyOModalPwaApps>["$props"];
	LazyOModalPwaNotification: InstanceType<typeof LazyOModalPwaNotification>["$props"];
	LazyOModalQuestAvailableTask: InstanceType<typeof LazyOModalQuestAvailableTask>["$props"];
	LazyOModalInsufficientFunds: InstanceType<typeof LazyOModalInsufficientFunds>["$props"];
	LazyOModalInsufficientWithdrawal: InstanceType<typeof LazyOModalInsufficientWithdrawal>["$props"];
	LazyOModalPromoHowItWorks: InstanceType<typeof LazyOModalPromoHowItWorks>["$props"];
	LazyOModalQuestHowItWorks: InstanceType<typeof LazyOModalQuestHowItWorks>["$props"];
	LazyOModalInviteFriends: InstanceType<typeof LazyOModalInviteFriends>["$props"];
	LazyOModalPresetPackage: InstanceType<typeof LazyOModalPresetPackage>["$props"];
	LazyOModalWelcome: InstanceType<typeof LazyOModalWelcome>["$props"];
	LazyOModalFlipTheCoin: InstanceType<typeof LazyOModalFlipTheCoin>["$props"];
	LazyOModalFlipTheCoinUsed: InstanceType<typeof LazyOModalFlipTheCoinUsed>["$props"];
	LazyOModalFlipTheCoinRules: InstanceType<typeof LazyOModalFlipTheCoinRules>["$props"];
	LazyOModalJackpotsGames: InstanceType<typeof LazyOModalJackpotsGames>["$props"];
	LazyOModalMoneyBox: InstanceType<typeof LazyOModalMoneyBox>["$props"];
	LazyOModalPromoInviteFriends: InstanceType<typeof LazyOModalPromoInviteFriends>["$props"];
	LazyOModalQuestLeaderBoard: InstanceType<typeof LazyOModalQuestLeaderBoard>["$props"];
	LazyOModalQuestWinnerNotification: InstanceType<typeof LazyOModalQuestWinnerNotification>["$props"];
	LazyOModalQuestWinnerAnimation: InstanceType<typeof LazyOModalQuestWinnerAnimation>["$props"];
	LazyOModalChallengeSeason: InstanceType<typeof LazyOModalChallengeSeason>["$props"];
	LazyOModalRulesChallengeSeason: InstanceType<typeof LazyOModalRulesChallengeSeason>["$props"];
	LazyOModalChallengeSeasonWinner: InstanceType<typeof LazyOModalChallengeSeasonWinner>["$props"];
	LazyOModalTaoExitPopup: InstanceType<typeof LazyOModalTaoExitPopup>["$props"];
	LazyOModalSeasonWelcome: InstanceType<typeof LazyOModalSeasonWelcome>["$props"];
	LazyOModalSeasonHowItWorks: InstanceType<typeof LazyOModalSeasonHowItWorks>["$props"];
	LazyOModalSeasonActivated: InstanceType<typeof LazyOModalSeasonActivated>["$props"];
	LazyOModalSeasonGames: InstanceType<typeof LazyOModalSeasonGames>["$props"];
	LazyOModalSeasonTower: InstanceType<typeof LazyOModalSeasonTower>["$props"];
	LazyOModalSeasonFishingGames: InstanceType<typeof LazyOModalSeasonFishingGames>["$props"];
	LazyOModalTournamentPrizes: InstanceType<typeof LazyOModalTournamentPrizes>["$props"];
	LazyOModalTournamentGames: InstanceType<typeof LazyOModalTournamentGames>["$props"];
	LazyOModalTournamentLeaderboard: InstanceType<typeof LazyOModalTournamentLeaderboard>["$props"];
	LazyOModalTournamentHowItWorks: InstanceType<typeof LazyOModalTournamentHowItWorks>["$props"];
	LazyOModalTournamentIncreaseBet: InstanceType<typeof LazyOModalTournamentIncreaseBet>["$props"];
	LazyOModalScratchCardsPlayForWin: InstanceType<typeof LazyOModalScratchCardsPlayForWin>["$props"];
	LazyOModalScratchCardsYouWon: InstanceType<typeof LazyOModalScratchCardsYouWon>["$props"];
	LazyOModalScratchCardsWelcome: InstanceType<typeof LazyOModalScratchCardsWelcome>["$props"];
	LazyOModalScratchCardsFeature: InstanceType<typeof LazyOModalScratchCardsFeature>["$props"];
	LazyOModalDepositStreak: InstanceType<typeof LazyOModalDepositStreak>["$props"];
	LazyOModalDepositStreakWelcome: InstanceType<typeof LazyOModalDepositStreakWelcome>["$props"];
	LazyOModalDepositStreakHowItWorks: InstanceType<typeof LazyOModalDepositStreakHowItWorks>["$props"];
	LazyOModalPiggyBankPrizeWin: InstanceType<typeof LazyOModalPiggyBankPrizeWin>["$props"];
	LazyOModalSwitchToSweeps: InstanceType<typeof LazyOModalSwitchToSweeps>["$props"];
	LazyOModalReferralInfo: InstanceType<typeof LazyOModalReferralInfo>["$props"];
	LazyOModalSuccessReferral: InstanceType<typeof LazyOModalSuccessReferral>["$props"];
	LazyOModalAffiliateTransfer: InstanceType<typeof LazyOModalAffiliateTransfer>["$props"];
};

const useTaoModals = () => useModals<Modals>();

export default useTaoModals;
